import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table, {
    Cell, Row,
} from '../components/Table';
import LaunchIcon from '@material-ui/icons/Launch';
import Lightbox from 'react-image-lightbox';
import camera_xf405_s from '../img/equipment/cameras/XF405_S.jpg';
import camera_xf405_l from '../img/equipment/cameras/XF405_L.jpg';
import camera_xf400_s from '../img/equipment/cameras/XF400_S.jpg';
import camera_xf400_l from '../img/equipment/cameras/XF400_L.jpg';
import camera_xf205_s from '../img/equipment/cameras/XF205_S.jpg';
import camera_xf205_l from '../img/equipment/cameras/XF205_L.jpg';
import camera_xa35_s from '../img/equipment/cameras/XA35_S.jpg';
import camera_xa35_l from '../img/equipment/cameras/XA35_L.jpg';
import camera_xa25_s from '../img/equipment/cameras/XA25_S.jpg';
import camera_xa25_l from '../img/equipment/cameras/XA25_L.jpg';
import camera_xa11_s from '../img/equipment/cameras/XA11_S.jpg';
import camera_xa11_l from '../img/equipment/cameras/XA11_L.jpg';
import camera_marshall_s from '../img/equipment/cameras/Marshall_S.jpg';
import camera_marshall_l from '../img/equipment/cameras/Marshall_L.jpg';
import camera_aida_s from '../img/equipment/cameras/AIDA_S.jpg';
import camera_aida_l from '../img/equipment/cameras/AIDA_L.jpg';
import camera_gopro5_s from '../img/equipment/cameras/GoPro5_S.jpg';
import camera_gopro5_l from '../img/equipment/cameras/GoPro5_L.jpg';
import camera_fusion_s from '../img/equipment/cameras/GoProFusion_S.jpg';
import camera_fusion_l from '../img/equipment/cameras/GoProFusion_L.jpg';
import camera_eosr_s from '../img/equipment/cameras/EOSR_S.jpg';
import camera_eosr_l from '../img/equipment/cameras/EOSR_L.jpg';
import lens_24to105_s from '../img/equipment/lenses/24to105_S.jpg';
import lens_24to105_l from '../img/equipment/lenses/24to105_L.jpg';
import lens_100to400_s from '../img/equipment/lenses/100to400_S.jpg';
import lens_100to400_l from '../img/equipment/lenses/100to400_L.jpg';
import lens_50_s from '../img/equipment/lenses/50_S.jpg';
import lens_50_l from '../img/equipment/lenses/50_L.jpg';
import lens_16_s from '../img/equipment/lenses/16_S.jpg';
import lens_16_l from '../img/equipment/lenses/16_L.jpg';
import projector_necPA622U_l from '../img/equipment/projector/NEC-PA622U_L.jpg';
import projector_necPA622U_s from '../img/equipment/projector/NEC-PA622U_S.jpg';
import mixer_atemConstellation_s from '../img/equipment/mixers/ATEM-Constellation_S.jpg';
import mixer_atemConstellation_l from '../img/equipment/mixers/ATEM-Constellation_L.jpg';
import mixer_atemSDIExtremeIso_s from '../img/equipment/mixers/ATEM-SDIExtremeIso_S.jpg';
import mixer_atemSDIExtremeIso_l from '../img/equipment/mixers/ATEM-SDIExtremeIso_L.jpg';
import mixer_atemMiniProIso_s from '../img/equipment/mixers/ATEM-MiniProIso_S.jpg';
import mixer_atemMiniProIso_l from '../img/equipment/mixers/ATEM-MiniProIso_L.jpg';
import mixer_atemMini_s from '../img/equipment/mixers/ATEM-Mini_S.jpg';
import mixer_atemMini_l from '../img/equipment/mixers/ATEM-Mini_L.jpg';
import recorder_hyperDeckStudioHDMini_s from '../img/equipment/recorders/HyperDeckStudioHDMini_S.jpg';
import recorder_hyperDeckStudioHDMini_l from '../img/equipment/recorders/HyperDeckStudioHDMini_L.jpg';
import recorder_hyperDeckStudioMini_s from '../img/equipment/recorders/HyperDeckStudioMini_S.jpg';
import recorder_hyperDeckStudioMini_l from '../img/equipment/recorders/HyperDeckStudioMini_L.jpg';
import recorder_hyperDeckStudio2_s from '../img/equipment/recorders/HyperDeckStudio2_S.jpg';
import recorder_hyperDeckStudio2_l from '../img/equipment/recorders/HyperDeckStudio2_L.jpg';
import recorder_videoassist_s from '../img/equipment/recorders/VideoAssist_S.jpg';
import recorder_videoassist_l from '../img/equipment/recorders/VideoAssist_L.jpg';
import wireless_cosmo_s from '../img/equipment/wireless/Cosmo_S.jpg';
import wireless_cosmo_l from '../img/equipment/wireless/Cosmo_L.jpg';
import audio_12mtk_s from '../img/equipment/audio/12MTK_S.jpg';
import audio_12mtk_l from '../img/equipment/audio/12MTK_L.jpg';
import audio_zoomH6_s from '../img/equipment/audio/ZoomH6_S.jpg';
import audio_zoomH6_l from '../img/equipment/audio/ZoomH6_L.jpg';
import audio_avxME2_s from '../img/equipment/audio/AVX-ME2_S.jpg';
import audio_avxME2_l from '../img/equipment/audio/AVX-ME2_L.jpg';
import audio_avx835_s from '../img/equipment/audio/AVX-835_S.jpg';
import audio_avx835_l from '../img/equipment/audio/AVX-835_L.jpg';
import audio_835_s from '../img/equipment/audio/835_S.jpg';
import audio_835_l from '../img/equipment/audio/835_L.jpg';
import audio_835s_s from '../img/equipment/audio/835S_S.jpg';
import audio_835s_l from '../img/equipment/audio/835S_L.jpg';
import audio_rodeNTG1_s from '../img/equipment/audio/RodeNTG1_S.jpg';
import audio_rodeNTG1_l from '../img/equipment/audio/RodeNTG1_L.jpg';
import audio_sonyNV1_s from '../img/equipment/audio/SonyECM-NV1_S.jpg';
import audio_sonyNV1_l from '../img/equipment/audio/SonyECM-NV1_L.jpg';
import audio_c2_s from '../img/equipment/audio/C2_S.jpg';
import audio_c2_l from '../img/equipment/audio/C2_L.jpg';
import audio_boya_s from '../img/equipment/audio/BoyaBY-MM1_S.jpg';
import audio_boya_l from '../img/equipment/audio/BoyaBY-MM1_L.jpg';
import audio_rodeSmartLav_s from '../img/equipment/audio/RodeSmartLav+_S.jpg';
import audio_rodeSmartLav_l from '../img/equipment/audio/RodeSmartLav+_L.jpg';
import tally_flextally_s from '../img/equipment/tally/Flextally_S.jpg';
import tally_flextally_l from '../img/equipment/tally/Flextally_L.jpg';
import tripod_manfrotto546B_s from '../img/equipment/tripods/Manfrotto546B_S.jpg';
import tripod_manfrotto546B_l from '../img/equipment/tripods/Manfrotto546B_L.jpg';
import tripod_manfrottoN8_s from '../img/equipment/tripods/ManfrottoN8_S.jpg';
import tripod_manfrottoN8_l from '../img/equipment/tripods/ManfrottoN8_L.jpg';
import tripod_siruiSH25_s from '../img/equipment/tripods/SiruiSH25_S.jpg';
import tripod_siruiSH25_l from '../img/equipment/tripods/SiruiSH25_L.jpg';
import tripod_manfrotto608_s from '../img/equipment/tripods/Manfrotto608_S.jpg';
import tripod_manfrotto608_l from '../img/equipment/tripods/Manfrotto608_L.jpg';
import tripod_kh26nl_s from '../img/equipment/tripods/KH26NL_S.jpg';
import tripod_kh26nl_l from '../img/equipment/tripods/KH26NL_L.jpg';
import tripod_a573tb_s from '../img/equipment/tripods/BenroA573TB_S.jpg';
import tripod_a573tb_l from '../img/equipment/tripods/BenroA573TB_L.jpg';
import tripod_genesis_s from '../img/equipment/tripods/Genesis_S.jpg';
import tripod_genesis_l from '../img/equipment/tripods/Genesis_L.jpg';
import tripod_benroS6_s from '../img/equipment/tripods/BenroS6_S.jpg';
import tripod_benroS6_l from '../img/equipment/tripods/BenroS6_L.jpg';
import tripod_manfrotto190db_s from '../img/equipment/tripods/Manfrotto190db_S.jpg';
import tripod_manfrotto190db_l from '../img/equipment/tripods/Manfrotto190db_L.jpg';
import tripod_manfrotto701rc2_s from '../img/equipment/tripods/Manfrotto701rc2_S.jpg';
import tripod_manfrotto701rc2_l from '../img/equipment/tripods/Manfrotto701rc2_L.jpg';
import tripod_bilora_s from '../img/equipment/tripods/Bilora_S.jpg';
import tripod_bilora_l from '../img/equipment/tripods/Bilora_L.jpg';
import tripod_manfrottoM128RC_s from '../img/equipment/tripods/ManfrottoM128RC_S.jpg';
import tripod_manfrottoM128RC_l from '../img/equipment/tripods/ManfrottoM128RC_L.jpg';
import dolly_d5t_s from '../img/equipment/dolly/D5T_S.jpg';
import dolly_d5t_l from '../img/equipment/dolly/D5T_L.jpg';
import stabilization_flycam_s from '../img/equipment/stabilization/Flycam5000_S.jpg';
import stabilization_flycam_l from '../img/equipment/stabilization/Flycam5000_L.jpg';
import stabilization_goProKarmaGrip_s from '../img/equipment/stabilization/GoProKarmaGrip_S.jpg';
import stabilization_goProKarmaGrip_l from '../img/equipment/stabilization/GoProKarmaGrip_L.jpg';
import communication_eartec_s from '../img/equipment/communication/Eartec_S.jpg';
import communication_eartec_l from '../img/equipment/communication/Eartec_L.jpg';
import capture_webPresenter_s from '../img/equipment/capture/WebPresenter_S.jpg';
import capture_webPresenter_l from '../img/equipment/capture/WebPresenter_L.jpg';
import capture_ultrastudioMiniRecorder_s from '../img/equipment/capture/UltrastudioMiniRecorder_S.jpg';
import capture_ultrastudioMiniRecorder_l from '../img/equipment/capture/UltrastudioMiniRecorder_L.jpg';
import capture_hd60s_s from '../img/equipment/capture/HD60S_S.jpg';
import capture_hd60s_l from '../img/equipment/capture/HD60S_L.jpg';
import controlSurface_atemMicroPanel_s from '../img/equipment/control-surfaces/atemMicroPanel_S.jpg';
import controlSurface_atemMicroPanel_l from '../img/equipment/control-surfaces/atemMicroPanel_L.jpg';
import controlSurface_streamDeck_s from '../img/equipment/control-surfaces/streamDeck_S.jpg';
import controlSurface_streamDeck_l from '../img/equipment/control-surfaces/streamDeck_L.jpg';
import controlSurface_streamDeckXL_s from '../img/equipment/control-surfaces/streamDeckXL_S.jpg';
import controlSurface_streamDeckXL_l from '../img/equipment/control-surfaces/streamDeckXL_L.jpg';
import signalprocessing_decimator_s from '../img/equipment/signalprocessing/Decimator_S.jpg';
import signalprocessing_decimator_l from '../img/equipment/signalprocessing/Decimator_L.jpg';
import signalprocessing_lumantek_sdiToHdmi_s from '../img/equipment/signalprocessing/Lumantek_sdiToHdmi_S.jpg';
import signalprocessing_lumantek_sdiToHdmi_l from '../img/equipment/signalprocessing/Lumantek_sdiToHdmi_L.jpg';
import signalprocessing_microconv_bidirect_s from '../img/equipment/signalprocessing/Microconv_bidirect_S.jpg';
import signalprocessing_microconv_bidirect_l from '../img/equipment/signalprocessing/Microconv_bidirect_L.jpg';
import signalprocessing_microconv_sdiToHdmi_s from '../img/equipment/signalprocessing/Microconv_sdiToHdmi_S.jpg';
import signalprocessing_microconv_sdiToHdmi_l from '../img/equipment/signalprocessing/Microconv_sdiToHdmi_L.jpg';
import signalprocessing_microconv_hdmiToSdi_s from '../img/equipment/signalprocessing/Microconv_hdmiToSdi_S.jpg';
import signalprocessing_microconv_hdmiToSdi_l from '../img/equipment/signalprocessing/Microconv_hdmiToSdi_L.jpg';
import signalprocessing_multiview_s from '../img/equipment/signalprocessing/Multiview_S.jpg';
import signalprocessing_multiview_l from '../img/equipment/signalprocessing/Multiview_L.jpg';
import signalprocessing_sdiToAudio_s from '../img/equipment/signalprocessing/SdiToAudio_S.jpg';
import signalprocessing_sdiToAudio_l from '../img/equipment/signalprocessing/SdiToAudio_L.jpg';
import signalprocessing_upDownCrossHD_s from '../img/equipment/signalprocessing/UpDownCrossHD_S.jpg';
import signalprocessing_upDownCrossHD_l from '../img/equipment/signalprocessing/UpDownCrossHD_L.jpg';
import signalprocessing_sdiDistribution_s from '../img/equipment/signalprocessing/sdiDistribution_S.jpg';
import signalprocessing_sdiDistribution_l from '../img/equipment/signalprocessing/sdiDistribution_L.jpg';

const categories = [
    {
        name: 'Videokamera',
        items: [
            {
                name: 'Canon XF405',
                count: 3,
                img: camera_xf405_s,
                imgLarge: camera_xf405_l,
                url: 'https://www.canon.no/video-cameras/xf-405-and-xf-400/',
            },
            {
                name: 'Canon XF400',
                count: 1,
                img: camera_xf400_s,
                imgLarge: camera_xf400_l,
                url: 'https://www.canon.no/video-cameras/xf-405-and-xf-400/',
            },
            {
                name: 'Canon XF205',
                count: 1,
                img: camera_xf205_s,
                imgLarge: camera_xf205_l,
                url: 'https://www.canon.no/for_home/product_finder/camcorders/professional/xf205/',
            },
            {
                name: 'Canon XA35',
                count: 1,
                img: camera_xa35_s,
                imgLarge: camera_xa35_l,
                url: 'https://www.canon-europe.com/for_home/product_finder/camcorders/professional/xa35/specification.html',
            },
            {
                name: 'Canon XA25',
                count: 1,
                img: camera_xa25_s,
                imgLarge: camera_xa25_l,
                url: 'https://www.canon.no/for_home/product_finder/camcorders/professional/xa25/',
            },
            {
                name: 'Canon XA11',
                count: 1,
                img: camera_xa11_s,
                imgLarge: camera_xa11_l,
                url: 'https://www.canon.no/pro/video-cameras/xa15-and-xa11/',
            },
            {
                name: 'Marshall CV502-M',
                count: 1,
                img: camera_marshall_s,
                imgLarge: camera_marshall_l,
                url: 'http://www.marshall-usa.com/discontinued/cameras/CV502-MB.php',
            },
            {
                name: 'AIDA HD3G-IPC-TF',
                count: 1,
                img: camera_aida_s,
                imgLarge: camera_aida_l,
                url: 'https://aidaimaging.com/sdi-ipc-series-cameras/',
            },
            {
                name: 'GoPro Hero 5',
                count: 3,
                img: camera_gopro5_s,
                imgLarge: camera_gopro5_l,
                url: 'https://gopro.com/content/dam/help/hero5-black/manuals/HERO5Black_UM_ENG_REVD_Web.pdf',
            },
            {
                name: 'GoPro Fusion',
                count: 1,
                img: camera_fusion_s,
                imgLarge: camera_fusion_l,
            },
        ]
    },
    {
        name: 'Kamera (utskiftbar optikk)',
        items: [
            {
                name: 'Canon EOS R',
                count: 1,
                img: camera_eosr_s,
                imgLarge: camera_eosr_l,
                url: 'https://www.canon.no/cameras/eos-r/',
            },
        ]
    },
    {
        name: 'Objektiv',
        items: [
            {
                name: 'Canon EF 24-105 f/4 IS USM',
                count: 1,
                img: lens_24to105_s,
                imgLarge: lens_24to105_l,
                url: 'https://www.canon.no/lenses/ef-24-105mm-f-4l-is-ii-usm-lens/',
            },
            {
                name: 'Sigma 100-400mm F5-6.3 Contemprorary',
                count: 1,
                img: lens_100to400_s,
                imgLarge: lens_100to400_l,
                url: 'https://www.sigma-global.com/en/lenses/c020_100_400_5_63/',
            },
            {
                name: 'Canon EF 50mm f/1.8 STM',
                count: 1,
                img: lens_50_s,
                imgLarge: lens_50_l,
                url: 'https://www.canon.no/lenses/ef-50mm-f-1-8-stm-lens/',
            },
            {
                name: 'Canon RF 16mm F2.8 STM',
                count: 1,
                img: lens_16_s,
                imgLarge: lens_16_l,
                url: 'https://www.canon.no/lenses/rf-16mm-f2-8-stm/',
            },
        ]
    },
    {
        name: 'Projektor',
        items: [
            {
                name: 'NEC PA622U',
                count: 1,
                img: projector_necPA622U_s,
                imgLarge: projector_necPA622U_l,
                url: 'https://www.sharpnecdisplays.eu/p/datasheet/no/datasheet/rp/PA622U.xhtml',
            },
        ]
    },
    {
        name: 'Bildemiksere',
        items: [
            {
                name: 'Blackmagic Design ATEM 2 M/E Constellation HD',
                count: 1,
                img: mixer_atemConstellation_s,
                imgLarge: mixer_atemConstellation_l,
                url: 'https://www.blackmagicdesign.com/products/atemconstellationhd/techspecs/W-APS-26',
            },
            {
                name: 'Blackmagic ATEM SDI Extreme ISO',
                count: 1,
                img: mixer_atemSDIExtremeIso_s,
                imgLarge: mixer_atemSDIExtremeIso_l,
                url: 'https://www.blackmagicdesign.com/products/atemmini/techspecs/W-APS-18',
            },
            {
                name: 'Blackmagic Design ATEM Mini Pro ISO',
                count: 1,
                img: mixer_atemMiniProIso_s,
                imgLarge: mixer_atemMiniProIso_l,
                url: 'https://www.blackmagicdesign.com/products/atemmini/techspecs/W-APS-15',
            },
            {
                name: 'Blackmagic Design ATEM Mini',
                count: 1,
                img: mixer_atemMini_s,
                imgLarge: mixer_atemMini_l,
            },
        ]
    },
    {
        name: 'Opptaksenheter',
        items: [
            {
                name: 'Blackmagic Design HyperDeck Studio HD Mini',
                count: 1,
                img: recorder_hyperDeckStudioHDMini_s,
                imgLarge: recorder_hyperDeckStudioHDMini_l,
                url: 'https://www.blackmagicdesign.com/products/hyperdeckstudio/techspecs/W-HYD-11',
            },
            {
                name: 'Blackmagic Design HyperDeck Studio Mini',
                count: 1,
                img: recorder_hyperDeckStudioMini_s,
                imgLarge: recorder_hyperDeckStudioMini_l,
            },
            {
                name: 'Blackmagic Design HyperDeck Studio 2',
                count: 1,
                img: recorder_hyperDeckStudio2_s,
                imgLarge: recorder_hyperDeckStudio2_l,
            },
            {
                name: 'Blackmagic Design Video Assist 5"',
                count: 1,
                img: recorder_videoassist_s,
                imgLarge: recorder_videoassist_l,
            },
        ]
    },
    {
        name: 'Trådløs video',
        items: [
            {
                name: 'Hollyland Cosmo 600',
                count: 1,
                img: wireless_cosmo_s,
                imgLarge: wireless_cosmo_l,
            },
        ]
    },
    {
        name: 'Lydutstyr',
        items: [
            {
                name: 'Soundcraft Signature 12 MTK lydmikser',
                count: 1,
                img: audio_12mtk_s,
                imgLarge: audio_12mtk_l,
                url: 'https://www.soundcraft.com/en/products/signature-12-mtk',
            },
            {
                name: 'Zoom H6 lydopptaker',
                count: 1,
                img: audio_zoomH6_s,
                imgLarge: audio_zoomH6_l,
                url: 'https://zoomcorp.com/en/us/handheld-recorders/handheld-recorders/h6-audio-recorder/',
            },
            {
                name: 'Sennheiser AVX-ME2 trådløst myggsett',
                count: 3,
                img: audio_avxME2_s,
                imgLarge: audio_avxME2_l,
                url: 'https://no-no.sennheiser.com/avx-me2-set',
            },
            {
                name: 'Sennheiser SKM AVX-835 trådløs mikrofon',
                count: 3,
                img: audio_avx835_s,
                imgLarge: audio_avx835_l,
                url: 'https://no-no.sennheiser.com/skm-avx-835',
            },
            {
                name: 'Sennheiser e 835',
                count: 1,
                img: audio_835_s,
                imgLarge: audio_835_l,
                url: 'https://no-no.sennheiser.com/live-performance-microphone-vocal-stage-e-835',
            },
            {
                name: 'Sennheiser e 835-S',
                count: 1,
                img: audio_835s_s,
                imgLarge: audio_835s_l,
                url: 'https://www.sennheiser.com/en-no/catalog/products/microphones/e-835/e-835-s-004514',
            },
            {
                name: 'Røde NTG1',
                count: 1,
                img: audio_rodeNTG1_s,
                imgLarge: audio_rodeNTG1_l,
                url: 'http://www.rode.com/microphones/ntg-1',
            },
            {
                name: 'Sony ECM-NV1',
                count: 2,
                img: audio_sonyNV1_s,
                imgLarge: audio_sonyNV1_l,
            },
            {
                name: 'Behringer C-2',
                count: 2,
                img: audio_c2_s,
                imgLarge: audio_c2_l,
                url: 'https://www.behringer.com/product.html?modelCode=P0263',
            },
            {
                name: 'BOYA BY-MM1',
                count: 1,
                img: audio_boya_s,
                imgLarge: audio_boya_l,
            },
            {
                name: 'Røde SmartLav+',
                count: 1,
                img: audio_rodeSmartLav_s,
                imgLarge: audio_rodeSmartLav_l,
                url: 'http://www.rode.com/microphones/smartlav-plus',
            },
        ]
    },
    {
        name: 'Tally',
        items: [
            {
                name: 'Cevero FlexTally',
                count: 6,
                img: tally_flextally_s,
                imgLarge: tally_flextally_l,
                url: 'https://flextally.cerevo.com/en/',
            },
        ]
    },
    {
        name: 'Stativ og stativhoder',
        items: [
            {
                name: 'Manfrotto 546B',
                count: 1,
                img: tripod_manfrotto546B_s,
                imgLarge: tripod_manfrotto546B_l,
                url: 'https://www.manfrotto.com/global/alu-twin-leg-with-middle-spreader-video-tripod-546b/',
            },
            {
                name: 'Manfrotto Nitrotech N8',
                count: 1,
                img: tripod_manfrottoN8_s,
                imgLarge: tripod_manfrottoN8_l,
                url: 'https://www.manfrotto.com/global/nitrotech-n8-fluid-video-head-with-continuous-cbs-mvhn8ah/',
            },
            {
                name: 'Sirui SH-25',
                count: 1,
                img: tripod_siruiSH25_s,
                imgLarge: tripod_siruiSH25_l,
                url: 'https://www.siruiusa.com/index/photographic/sh15.html?cid=2&id=72',
            },
            {
                name: 'Manfrotto Nitrotech 608',
                count: 1,
                img: tripod_manfrotto608_s,
                imgLarge: tripod_manfrotto608_l,
                url: 'https://www.manfrotto.com/us-en/nitrotech-608-fluid-video-head-with-continuous-cbs-mvh608ahus/',
            },
            {
                name: 'Benro KH26NL',
                count: 1,
                img: tripod_kh26nl_s,
                imgLarge: tripod_kh26nl_l,
            },
            {
                name: 'Benro A573TB Alu + S6Pro',
                count: 1,
                img: tripod_a573tb_s,
                imgLarge: tripod_a573tb_l,
            },
            {
                name: 'Genesis Base CVT-20',
                count: 1,
                img: tripod_genesis_s,
                imgLarge: tripod_genesis_l,
                url: 'https://genesisgear.eu/en/produkt/genesis-cvt-20-kit-2/',
            },
            {
                name: 'Benro S6',
                count: 1,
                img: tripod_benroS6_s,
                imgLarge: tripod_benroS6_l,
                url: 'https://benrousa.com/s6-6kg-video-head-stepped-0-2-5kg-4-5kg-6kg-90-50-tilt-range/',
            },
            {
                name: 'Manfrotto 190DB',
                count: 1,
                img: tripod_manfrotto190db_s,
                imgLarge: tripod_manfrotto190db_l,
            },
            {
                name: 'Manfrotto 701RC2',
                count: 1,
                img: tripod_manfrotto701rc2_s,
                imgLarge: tripod_manfrotto701rc2_l,
            },
            {
                name: 'Bilora PerfectPro Alu A 324',
                count: 1,
                img: tripod_bilora_s,
                imgLarge: tripod_bilora_l,
            },
            {
                name: 'Manfrotto M128RC',
                count: 1,
                img: tripod_manfrottoM128RC_s,
                imgLarge: tripod_manfrottoM128RC_l,
            }
        ]
    },
    {
        name: 'Dolly',
        items: [
            {
                name: 'Hague D5T m/ 12 meter track',
                count: 1,
                img: dolly_d5t_s,
                imgLarge: dolly_d5t_l,
                url: 'https://www.haguecamerasupports.com/tracking-dolly-systems/hague-d5t-tripod-tracking-dolly-kit',
            },
        ]
    },
    {
        name: 'Stabilisering',
        items: [
            {
                name: 'Flycam 5000',
                count: 1,
                img: stabilization_flycam_s,
                imgLarge: stabilization_flycam_l,
                url: 'https://www.proaim.com/products/flycam-5000-camera-stabilizer-system-with-comfort-arm-and-vest',
            },
            {
                name: 'GoPro Karma Grip',
                count: 1,
                img: stabilization_goProKarmaGrip_s,
                imgLarge: stabilization_goProKarmaGrip_l,
            },
        ]
    },
    {
        name: 'Kommunikasjon',
        items: [
            {
                name: 'Eartec UltraLITE 4 Dobbeltøre Headset',
                count: 4,
                img: communication_eartec_s,
                imgLarge: communication_eartec_l,
                url: 'https://eartec.com/ultralite/',
            },
        ]
    },
    {
        name: 'Streaming/capture',
        items: [
            {
                name: 'Blackmagic Design Web Presenter HD',
                count: 1,
                img: capture_webPresenter_s,
                imgLarge: capture_webPresenter_l,
                url: 'https://www.blackmagicdesign.com/products/blackmagicwebpresenter/techspecs/W-WPR-02',
            },
            {
                name: 'Blackmagic Design UltraStudio Mini Recorder',
                count: 1,
                img: capture_ultrastudioMiniRecorder_s,
                imgLarge: capture_ultrastudioMiniRecorder_l,
            },
            {
                name: 'Elgato Game Capture HD60S',
                count: 1,
                img: capture_hd60s_s,
                imgLarge: capture_hd60s_l,
            },
        ]
    },
    {
        name: 'Kontrollenheter',
        items: [
            {
                name: 'Blackmagic Design ATEM Micro Panel',
                count: 1,
                img: controlSurface_atemMicroPanel_s,
                imgLarge: controlSurface_atemMicroPanel_l,
                url: 'https://www.blackmagicdesign.com/products/atemconstellation/techspecs/W-ABP-11',
            },
            {
                name: 'Elgato Stream Deck XL',
                count: 1,
                img: controlSurface_streamDeckXL_s,
                imgLarge: controlSurface_streamDeckXL_l,
                url: 'https://www.elgato.com/en/stream-deck-xl',
            },
            {
                name: 'Elgato Stream Deck',
                count: 1,
                img: controlSurface_streamDeck_s,
                imgLarge: controlSurface_streamDeck_l,
                url: 'https://www.elgato.com/en/stream-deck',
            }
        ]
    },
    {
        name: 'Signalprosessering',
        items: [
            {
                name: 'Decimator MD-HX',
                count: 1,
                img: signalprocessing_decimator_s,
                imgLarge: signalprocessing_decimator_l,
                url: 'http://www.decimator.com/Products/MiniConverters/MD-HX/MD-HX.html',
            },
            {
                name: 'Lumantek SDI to HDMI converter/scaler',
                count: 1,
                img: signalprocessing_lumantek_sdiToHdmi_s,
                imgLarge: signalprocessing_lumantek_sdiToHdmi_l,
                url: 'http://lumantek.com/index/product_intro/59',
            },
            {
                name: 'Blackmagic Design Micro Converter BiDirect SDI/HDMI',
                count: 5,
                img: signalprocessing_microconv_bidirect_s,
                imgLarge: signalprocessing_microconv_bidirect_l,
            },
            {
                name: 'Blackmagic Design Micro Converter SDI to HDMI',
                count: 10,
                img: signalprocessing_microconv_sdiToHdmi_s,
                imgLarge: signalprocessing_microconv_sdiToHdmi_l,
            },
            {
                name: 'Blackmagic Design Micro Converter HDMI to SDI',
                count: 4,
                img: signalprocessing_microconv_hdmiToSdi_s,
                imgLarge: signalprocessing_microconv_hdmiToSdi_l,
            },
            {
                name: 'Blackmagic Design Multiview 4 HD',
                count: 1,
                img: signalprocessing_multiview_s,
                imgLarge: signalprocessing_multiview_l,
                url: 'https://www.blackmagicdesign.com/products/multiview/techspecs/W-MVW-03',
            },
            {
                name: 'Blackmagic Design Mini Converter SDI to Audio',
                count: 1,
                img: signalprocessing_sdiToAudio_s,
                imgLarge: signalprocessing_sdiToAudio_l,
                url: 'https://www.blackmagicdesign.com/products/miniconverters/techspecs/W-CONM-22',
            },
            {
                name: 'Blackmagic Design Mini Converter UpDownCross HD',
                count: 1,
                img: signalprocessing_upDownCrossHD_s,
                imgLarge: signalprocessing_upDownCrossHD_l,
                url: 'https://www.blackmagicdesign.com/products/miniconverters/techspecs/W-CONM-28',
            },
            {
                name: 'Blackmagic Design Mini Converter SDI Distribution',
                count: 1,
                img: signalprocessing_sdiDistribution_s,
                imgLarge: signalprocessing_sdiDistribution_l,
                url: 'https://www.blackmagicdesign.com/products/miniconverters/techspecs/W-CONM-13',
            },
        ]
    },
];

const Equipment = () => {
    const classes = useStyles();
    const [lightboxSrc, setLightboxSrc] = React.useState('');
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);

    return (
        <>
            {isLightboxOpen && (
                <Lightbox
                    mainSrc={lightboxSrc}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    enableZoom={false}
                />
            )}

            <div className={classes.pageWrapper}>
                <Typography variant="h3" className={classes.header}>Utstyr</Typography>

                <Typography className={classes.introParagraph} variant="body1">
                    Dette er noe av videoutstyret VAVIT har til rådighet.
                </Typography>

                {categories.map(category => (
                    <div key={category.name}>
                        <Typography variant="h5" className={classes.categoryHeader}>{category.name}</Typography>
                        <Paper className={classes.categoryPaper}>
                            <Table className={classes.table} hideHeader header={['', '']} hidePagination>
                                {category.items.map(item => (
                                    <Row key={item.name} className={classes.tableRow}>
                                        <Cell className={classes.textCell}>
                                            <strong style={{ paddingLeft: '.2rem' }}>{item.name}</strong>
                                            <br />
                                            {item.count > 0 && (
                                                <div style={{ paddingLeft: '.2rem' }}>{`${item.count} stk.`}</div>
                                            )}
                                            {item.url && (
                                                <Button
                                                    // variant="outlined"
                                                    size="small"
                                                    className={classes.linkBtn}
                                                    endIcon={<LaunchIcon />}
                                                    onClick={() => { window.open(item.url, "_blank") }}
                                                >
                                                    Produktinfo
                                                </Button>
                                            )}
                                            {item.accessories && (
                                                <div className={classes.accessories}>
                                                    {item.accessories.map(accessory => (
                                                        <div
                                                            className={classes.accessory}
                                                            key={accessory}
                                                        >
                                                            {accessory}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </Cell>
                                        <Cell className={classes.imgCell}>
                                            <img
                                                src={item.img}
                                                alt={item.name}
                                                className={classes.img}
                                                onClick={() => {
                                                    setLightboxSrc(item.imgLarge);
                                                    setIsLightboxOpen(true);
                                                }}
                                            />
                                        </Cell>
                                    </Row>
                                ))}
                            </Table>
                        </Paper>
                    </div>
                ))}
            </div>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        maxWidth: '40rem',
        margin: '0 auto',
    },
    header: {
        textAlign: 'center',
    },
    introParagraph: {
        margin: '2rem 0',
    },
    categoryHeader: {
        marginTop: '2rem',
        marginBottom: '1rem',
        fontWeight: 'bold',
    },
    categoryPaper: {
        marginBottom: '5rem',
    },
    table: {
        minWidth: '15rem',
    },
    tableRow: {
        height: '7rem',
    },
    textCell: {
        width: '60%',
        fontSize: '1rem',
        lineHeight: '2rem',
    },
    linkBtn: {
        fontSize: '.7rem',
    },
    imgCell: {
        textAlign: 'center',
    },
    img: {
        maxHeight: '5rem',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '5rem',
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '14rem',
        },

        '&:hover': {
            cursor: 'pointer',
        },
    },
    accessories: {
        marginTop: '.5rem',
    },
    accessory: {
        lineHeight: '1.2rem',
        paddingLeft: '.2rem',
        fontSize: '.7rem',
        color: '#434343',
    },
}));

export default Equipment;
